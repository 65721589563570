import { postDataAPI } from '../../config/fetchData'
import valid from '../../config/valid'
import * as types from "./ActionTypes";

export const login = (data) => async (dispatch, navigate) => {
  try {
      dispatch({ type: types.ACTION_TYPES.ACTION_ALERT, payload: {loading: true} })
      const res = await postDataAPI('login', data)
      dispatch({ 
          type: types.ACTION_TYPES.ACTION_AUTH, 
          payload: {
            token: res.data.access_token,
            user: res.data.user
          } 
      })

    //   localStorage.setItem("firstLogin", true)
      dispatch({ 
          type: types.ACTION_TYPES.ACTION_ALERT, 
          payload: {
              success: res.data.message
          } 
      })
      dispatch(() => navigate("/"))
  } catch (err) {
      dispatch({ 
          type: types.ACTION_TYPES.ACTION_ALERT, 
          payload: {
              error: err.response.data.message
          } 
      })
  }
}


export const refreshToken = () => async (dispatch) => {
    const firstLogin = localStorage.getItem("firstLogin")
    if(firstLogin){
        dispatch({ type: types.ACTION_TYPES.ACTION_ALERT, payload: {loading: true} })

        try {
            const res = await postDataAPI('refresh_token')
            dispatch({ 
                type: types.ACTION_TYPES.ACTION_AUTH, 
                payload: {
                    token: res.data.access_token,
                    user: res.data.createdUser
                } 
            })

            dispatch({ type: types.ACTION_TYPES.ACTION_ALERT, payload: {} })

        } catch (err) {
            dispatch({ 
                type: types.ACTION_TYPES.ACTION_ALERT, 
                payload: {
                    error: err.response.data.message
                } 
            })
        }
    }
}

export const register = (data) => async (dispatch) => {
  const check = valid(data)
  if(check.errLength > 0) {
    return dispatch({type: types.ACTION_TYPES.ACTION_ALERT, payload: check.errmessage})
  }

  try {
      dispatch({type: types.ACTION_TYPES.ACTION_ALERT, payload: {loading: true}})

      const res = await postDataAPI('register', data)
      dispatch({ 
          type: types.ACTION_TYPES.ACTION_AUTH, 
          payload: {
              token: res.data.access_token,
              user: res.data.createdUser
          } 
      })

    //   localStorage.setItem("firstLogin", true)
      dispatch({ 
          type: types.ACTION_TYPES.ACTION_ALERT, 
          payload: {
              success: res.data.message
          } 
      })
  } catch (err) {
      dispatch({ 
          type: types.ACTION_TYPES.ACTION_ALERT, 
          payload: {
              error: err.response.data.message
          } 
      })
  }
}

// export const logout = () => async (dispatch) => {
//     try {
//         // localStorage.removeItem('firstLogin')
//         await postDataAPI('logout')
//         window.location.href = "/signin"
//     } catch (err) {
//         dispatch({ 
//             type: types.ACTION_TYPES.ACTION_ALERT, 
//             payload: {
//                 error: err.response.data.message
//             } 
//         })
//     }
// }