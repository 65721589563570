import React from "react";

function TopCategories() {
  return (
    <section className="categories-one">
      <div className="container">
        <div className="section-title text-center">
          <span className="section-title__tagline">Explore new list</span>
          <h2 className="section-title__title">Highly in demand</h2>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="categories-one__wrapper">
              <div className="row">
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="0ms"
                  data-wow-duration="1500ms"
                >
                  <div className="categories-one__single">
                    <div className="categories-one__single-img">
                      <img
                        src={require("../../../assets/images/resources/categories-v1-img1.jpg")}
                        alt=""
                      />
                      <div className="categories-one__single-overlay">
                        <div className="categories-one__single-overlay-text1">
                          <p>30 full courses</p>
                        </div>
                        <div className="categories-one__single-overlay-text2">
                          <h4>Art & Design</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <div className="categories-one__single">
                    <div className="categories-one__single-img">
                      <img
                        src={require("../../../assets/images/resources/categories-v1-img2.jpg")}
                        alt=""
                      />
                      <div className="categories-one__single-overlay">
                        <div className="categories-one__single-overlay-text1">
                          <p>30 full courses</p>
                        </div>
                        <div className="categories-one__single-overlay-text2">
                          <h4>Art & Design</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="400ms"
                  data-wow-duration="1500ms"
                >
                  <div className="categories-one__single">
                    <div className="categories-one__single-img">
                      <img
                        src={require("../../../assets/images/resources/categories-v1-img3.jpg")}
                        alt=""
                      />
                      <div className="categories-one__single-overlay">
                        <div className="categories-one__single-overlay-text1">
                          <p>30 full courses</p>
                        </div>
                        <div className="categories-one__single-overlay-text2">
                          <h4>Art & Design</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="categories-one__single">
                    <div className="categories-one__single-img">
                      <img
                        src={require("../../../assets/images/resources/categories-v1-img4.jpg")}
                        alt=""
                      />
                      <div className="categories-one__single-overlay">
                        <div className="categories-one__single-overlay-text1">
                          <p>30 full courses</p>
                        </div>
                        <div className="categories-one__single-overlay-text2">
                          <h4>Art & Design</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="categories-one__btn text-center">
              <a href="#" className="thm-btn">
                view all courses
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopCategories;
