import React, {useState, useEffect} from 'react'
import TopHeader from "../../components/shared/header/TopHeader";
import Footer from "../../components/shared/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";

function Register() {
  const { auth, alert } = useSelector(state => state)
  const dispatch = useDispatch();
  const initialState = { name: '',  email: '', password: '', cf_password: ''}
  const [userData, setUserData] = useState(initialState)
  const { name, email, password, cf_password } = userData
  const navigate = useNavigate()

//   useEffect(() => {
//     if(auth.token) navigate("/")
// }, [auth.token, navigate])


  const handleChangeInput = e => {
    const { name, value } = e.target
    setUserData({...userData, [name]:value})
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(register(userData))
  }

  return (
    <>
        <TopHeader />
        <div style={{
                width: "100%",
                height: "100%", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                marginTop: "-3rem"
            }}
        >
            <div
              className="registration-one__right wow slideInRight"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <div className="registration-one__right-form" style={{width: "100%"}}>
                <div className="title-box">
                  <h4>Fill Up Your Registration Form</h4>
                </div>
                <div className="form-box">
                  <form method="post" action="index.html">
                  <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        placeholder="Full Name"
                        required=""
                        onChange={handleChangeInput}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email Address"
                        required=""
                        onChange={handleChangeInput}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        required=""
                        onChange={handleChangeInput}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="cf_password"
                        value={cf_password}
                        placeholder="Confirm Password"
                        required=""
                        onChange={handleChangeInput}
                      />
                    </div>
                    <button
                      className="registration-one__right-form-btn"
                      type="submit"
                      name="submit-form"
                      style={{margin: "2rem 0rem"}}
                      onClick={handleSubmit}
                    >
                      <span className="thm-btn">Sign up</span>
                    </button>
                    <div style={{
                        width: "100%",
                        height: "100%", 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center",
                        flexDirection: "column"
                    }}
                    >
                      <p>Already have an account? <a href="/login">Sign In</a></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        <Footer />
    </>
  )
}

export default Register