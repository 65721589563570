import React from 'react'

const Toast = ({message, handleShow, bgColor}) => {
  return (
    <div className={`toast show position-fixed text-light ${bgColor}`}
        style={{top: '5px', right: '5px', minWidth: '200px', zIndex: 5000}}>
            <div className={`toast-header text-light ${bgColor}`}>
                <strong className="mr-auto text-light">{message.title}</strong>
                <button className="ml-2 mb-1 close text-light"
                data-dismiss="toast" 
                style={{
                    outline: 'none', 
                    backgroundColor: 'transparent',
                    border: 'none',
                    marginLeft: '2rem',
                    position: 'absolute',
                    top: '0%',
                    right: '0%'
                }}
                onClick={handleShow}>
                    <i class='bx bx-window-close'></i>
                </button>
            </div>
            <div className="toast-body">
                {message.body}
            </div>
        </div>
  )
}

export default Toast