import React from "react";

function HomeFeatures() {
  return (
    <section className="features-one">
      <div className="container">
        <div className="row">
          <div
            className="col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div className="features-one__single">
              <div className="features-one__single-icon">
                <span className="icon-empowerment"></span>
              </div>
              <div className="features-one__single-text">
                <h4>
                  <a href="#">Learn Skills</a>
                </h4>
                <p>you need to master today’s job market</p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="features-one__single">
              <div className="features-one__single-icon">
                <span className="icon-human-resources-1"></span>
              </div>
              <div className="features-one__single-text">
                <h4>
                  <a href="#">Expert Teachers</a>
                </h4>
                <p>best & highly qualified</p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-4 wow fadeInUp"
            data-wow-delay="400ms"
            data-wow-duration="1500ms"
          >
            <div className="features-one__single">
              <div className="features-one__single-icon">
                <span className="icon-recruitment"></span>
              </div>
              <div className="features-one__single-text">
                <h4>
                  <a href="#">Get Certified!</a>
                </h4>
                <p>value all over the world</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeFeatures;
