import React from "react";
import TopHeader from "../../components/shared/header/TopHeader";
import Footer from "../../components/shared/footer/Footer";
import Newsletter from "./features/Newsletter";
import Blogs from "./features/Blogs";
import Testimonials from "./features/Testimonials";
import TopCategories from "./features/TopCategories";
import HomeRegister from "./features/HomeRegister";
import HomeCourses from "./features/HomeCourses";
import HomeAbout from "./features/HomeAbout";
import HomeFeatures from "./features/HomeFeatures";
import Slider from "./features/Slider";

function Home() {
  return (
    <>
      <TopHeader />
      <div className="stricky-header stricked-menu main-menu">
        <div className="sticky-header__content"></div>
      </div>
      <Slider />
      <HomeFeatures />
      <HomeAbout />
      <HomeCourses />
      <HomeRegister />
      <TopCategories />
      <Testimonials />
      <Blogs />
      <Newsletter />
      <Footer />
    </>
  );
}

export default Home;
