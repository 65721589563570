import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


function TopHeader() {
  const { auth } = useSelector(state => state);
  return (
    <header className="main-header main-header--one  clearfix">
      <div className="main-header--one__top clearfix">
        <div className="container">
          <div className="main-header--one__top-inner clearfix">
            <div className="main-header--one__top-left">
              <div className="main-header--one__top-logo">
                <a href="index.html">
                  <img
                    src={require("../../../assets/images/logo.png")}
                    alt=""
                  />
                </a>
              </div>
            </div>

            <div className="main-header--one__top-right clearfix">
              <ul className="main-header--one__top-social-link list-unstyled clearfix">
                <li>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>

              <div className="main-header--one__top-contact-info clearfix">
                <ul className="main-header--one__top-contact-info-list list-unstyled">
                  <li className="main-header--one__top-contact-info-list-item">
                    <div className="icon">
                      <span className="icon-phone-call-1"></span>
                    </div>
                    <div className="text">
                      <h6>Call Agent</h6>
                      <p>
                        <a href="tel:09037000088">+2349037000088</a>
                      </p>
                    </div>
                  </li>
                  <li className="main-header--one__top-contact-info-list-item">
                    <div className="icon">
                      <span className="icon-message"></span>
                    </div>
                    <div className="text">
                      <h6>Call Agent</h6>
                      <p>
                        <a href="mailto:info@templatepath.com">
                          success@skilltube.com
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-header-one__bottom clearfix">
        <div className="container">
          <div className="main-header-one__bottom-inner clearfix">
            <nav className="main-menu main-menu--1">
              <div className="main-menu__inner">
                <a href="#" className="mobile-nav__toggler">
                  <i className="fa fa-bars"></i>
                </a>

                <div className="left">
                  <ul className="main-menu__list">
                    <li className="dropdown current">
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="about.html">About</a>
                    </li>
                    <li className="dropdown">
                      <a href="#">Courses</a>
                    </li>
                    <li className="dropdown">
                      <a href="#"> Teachers</a>
                      <ul>
                        <li>
                          <a href="teachers-1.html"> Teachers</a>
                        </li>
                        <li>
                          <a href="teachers-2.html">Become Teacher</a>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown">
                      <a href="#">News</a>
                      <ul>
                        <li>
                          <a href="news.html">News</a>
                        </li>
                        <li>
                          <a href="news-details.html">News Details</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>

                <div className="right">
                  <div className="main-menu__right">
                    <div className="main-menu__right-login-register">
                      <ul className="list-unstyled">
                        {
                          auth.token ? <li>Logged in</li>
                          :
                          <><li>
                              <Link to={"/login"}>Login</Link>
                            </li><li>
                                <Link to={"/register"}>Register</Link>
                              </li></>
                        }
                      </ul>
                    </div>
                    <div className="main-menu__right-cart-search">
                      <div className="main-menu__right-cart-box">
                        <a href="#">
                          <span className="icon-shopping-cart"></span>
                        </a>
                      </div>
                      <div className="main-menu__right-search-box">
                        <a href="#" className="thm-btn search-toggler">
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default TopHeader;
