import React from "react";

function Footer() {
  return (
    <footer className="footer-one">
      <div
        className="footer-one__bg"
        style={{
          backgroundImage: "url(/footerbg.png)",
        }}
      ></div>
      <div className="footer-one__top">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="footer-widget__column footer-widget__about">
                <div className="footer-widget__about-logo">
                  <a href="index.html">
                    <img src="assets/images/resources/footer-logo.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="footer-widget__column footer-widget__courses">
                <h3 className="footer-widget__title">Courses</h3>
                <ul className="footer-widget__courses-list list-unstyled">
                  <li>
                    <a href="/">Cybersecurity</a>
                  </li>
                  <li>
                    <a href="/">CompTIA</a>
                  </li>
                  <li>
                    <a href="/">Cloud Service</a>
                  </li>
                  <li>
                    <a href="/">DevOps / Blockchain</a>
                  </li>
                  <li>
                    <a href="/">Social Media and Marketing</a>
                  </li>
                  <li>
                    <a href="/">Project Management</a>
                  </li>
                  <li>
                    <a href="/"> App Development</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="footer-widget__column footer-widget__links">
                <h3 className="footer-widget__title">Links</h3>
                <ul className="footer-widget__links-list list-unstyled">
                  <li>
                    <a href="/">About Us</a>
                  </li>
                  <li>
                    <a href="/">Overview</a>
                  </li>
                  <li>
                    <a href="/">Facilitators</a>
                  </li>
                  <li>
                    <a href="/">Join Us</a>
                  </li>
                  <li>
                    <a href="/">Our News</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="footer-widget__column footer-widget__contact">
                <h3 className="footer-widget__title">Contact</h3>
                <p className="text">54A Adebajo. New Bodija, Ibadan</p>
                <p>
                  <a href="mailto:info@templatepath.com">
                    success@skilltube.com
                  </a>
                </p>
                <p className="phone">
                  <a href="tel:123456789">+2349037000088</a>
                </p>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp"
              data-wow-delay="0.9s"
            >
              <div className="footer-widget__column footer-widget__social-links">
                <ul className="footer-widget__social-links-list list-unstyled clearfix">
                  <li>
                    <a href="/">
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-one__bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="footer-one__bottom-inner">
                <div className="footer-one__bottom-text text-center">
                  <p>&copy; Copyright 2021 by Skilltube.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
