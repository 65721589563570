import * as types from "../actions/ActionTypes";
import { EditData } from '../actions/controllers'


const initialState = {
    loading: false,
    ids: [],
    users: [],
    courses: []
}

const profileReducer = (state = initialState, action) => {
    switch (action.type){
        case types.PROFILE_TYPES.PROFILE_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case types.PROFILE_TYPES.PROFILE_USER:
            return {
                ...state,
                users: [...state.users, action.payload.user]
            };
        case types.PROFILE_TYPES.PROFILE_GET_ID:
            return {
                ...state,
                ids: [...state.ids, action.payload]
            };
        case types.PROFILE_TYPES.PROFILE_COURSE:
            return {
                ...state,
                courses: [...state.courses, action.payload]
            };
        case types.PROFILE_TYPES.PROFILE_COURSE_UPDATE:
            return {
                ...state,
                courses: EditData(state.courses, action.payload.id, action.payload)
            };
        default:
            return state;
    }
}

export default profileReducer