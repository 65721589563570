import * as types from "../actions/ActionTypes";
import { EditData, DeleteData } from '../actions/controllers'

const initialState = {
    loading: false,
    courses: [],
    result: 0,
    page: 2
}

const courseReducer = (state = initialState, action) => {
    switch (action.type){
        case types.COURSE_TYPES.CREATE_COURSE:
            return {
                ...state,
                courses: [action.payload, ...state.courses]
            };
        case types.COURSE_TYPES.LOADING_COURSE:
            return {
                ...state,
                loading: action.payload
            };
        case types.COURSE_TYPES.GET_COURSES:
            return {
                ...state,
                courses: action.payload.courses,
                result: action.payload.result,
                page: action.payload.page
            };
        case types.COURSE_TYPES.UPDATE_COURSE:
            return {
                ...state,
                courses: EditData(state.courses, action.payload.id, action.payload)
            };
        case types.COURSE_TYPES.DELETE_COURSE:
            return {
                ...state,
                courses: DeleteData(state.courses, action.payload.id)
            };
        default:
            return state;
    }
}

export default courseReducer