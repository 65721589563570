import React from "react";

function Newsletter() {
  return (
    <section className="newsletter-one">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="newsletter-one__left">
              <div className="section-title">
                <h2 className="section-title__title">
                  Subscribe to Our <br />
                  Newsletter to Get Daily
                  <br />
                  Content!
                </h2>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6">
            <div className="newsletter-one__right">
              <div className="shape1 zoom-fade">
                <img src="assets/images/shapes/thm-shape2.png" alt="" />
              </div>
              <div
                className="shape2 wow slideInRight"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <img src="assets/images/shapes/thm-shape3.png" alt="" />
              </div>
              <div
                className="newsletter-form wow slideInDown"
                data-wow-delay="100ms"
                data-wow-duration="1500ms"
              >
                <form action="#">
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter your email"
                  />
                  <button type="submit">
                    <span className="icon-paper-plane"></span>
                  </button>
                </form>
                <div className="newsletter-one__right-checkbox">
                  <input
                    type="checkbox"
                    name="agree "
                    id="agree"
                    defaultChecked
                  />
                  <label htmlFor="agree">
                    <span></span>I agree to all terms and policies of the
                    company
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
