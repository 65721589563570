import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Slider() {
  return (
    <section className="main-slider main-slider-one">
      <Carousel
        autoPlay
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        stopOnHover
        // onChange={onChange}
        // onClickItem={onClickItem}
        // onClickThumb={onClickThumb}
      >
        <div>
          <img src={require("../../../assets/images/slider/11.png")} />
          {/* <div
            style={{
              transition: "all .5s ease-in-out",
              position: "absolute",
              top: "40px",
              width: "100%",
              padding: "10px",
            }}
          >
            <h2
              style={{
                color: "white",
                padding: 20,
                fontSize: "60px",
                textAlign: "left",
              }}
            >
              Learn new skills <br /> Get a job
              <br /> Make money
            </h2>
            <div>
              <h2
                style={{
                  textAlign: "left",
                  padding: 20,
                  marginLeft: 20,
                  background: "rgba(255, 255, 255, .1)",
                  borderRadius: 25,
                  width: "50%",
                  color: "#0252d4",
                }}
              >
                Your choices are endless, your time is not.
                <br /> Up skill Now!
              </h2>
            </div>
          </div> */}
        </div>
        <div>
          <img src={require("../../../assets/images/slider/22.png")} />
          {/* <div
            style={{
              transition: "all .5s ease-in-out",
              position: "absolute",
              top: "40px",
              width: "100%",
              padding: "10px",
            }}
          >
            <h2
              style={{
                color: "white",
                padding: 20,
                fontSize: "60px",
                textAlign: "center",
              }}
            >
              <h2>Fill the skill gap</h2>
              <h2>Get the Skills Employers Want Now</h2>
            </h2>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  padding: 20,
                  marginLeft: 20,
                  background: "rgba(255, 255, 255, .1)",
                  borderRadius: 25,
                  width: "50%",
                  color: "#0252d4",
                }}
              >
                Identify opportunities, uncover new skills and stand out from
                <br /> other job seekers.
              </h2>
            </div>
          </div> */}
        </div>
        <div>
          <img src={require("../../../assets/images/slider/33.png")} />
          {/* <p className="legend">
            <span style={{ fontSize: 30 }}>
              Gain affordable access to skills, improve your chance of landing a
              better job, promotion, or even your dream job
            </span>
          </p> */}
        </div>
        <div>
          <img src={require("../../../assets/images/slider/44.png")} />
          {/* <p className="legend">Legend 4</p> */}
        </div>
      </Carousel>
    </section>
  );
}

export default Slider;
