import * as types from "../actions/ActionTypes";

const initialState = {}

const authReducer = (state = initialState, action) => {
    switch (action.type){
        case types.ACTION_TYPES.ACTION_AUTH:
            return action.payload;
        default:
            return state;
    }
}


export default authReducer