import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as types from '../../../redux/actions/ActionTypes'

// import Loading from './Loading'
import Toast from './Toast'

const Notify = () => {
    const { alert } = useSelector(state => state)
    const dispatch = useDispatch()

    return (
        <div>
            {/* {alert.loading && <Loading />} */}

            {
                alert.error && 
                <Toast message={{title: 'Error', body: alert.error}}
                handleShow={() => dispatch({type: types.ACTION_TYPES.ACTION_ALERT, payload: {}})} 
                bgColor="bg-danger" />
            }

            {
                alert.success && 
                <Toast message={{title: 'Success', body: alert.success}} 
                handleShow={() => dispatch({type: types.ACTION_TYPES.ACTION_ALERT, payload: {}})}
                bgColor="bg-success" />
            }
        </div>
    )
}

export default Notify