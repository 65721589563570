import React from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

function Testimonials() {
  return (
    <section className="testimonials-one clearfix">
      <div className="auto-container">
        <div className="section-title text-center">
          <span className="section-title__tagline">Our Testimonials</span>
          <h2 className="section-title__title">What They Say?</h2>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="testimonials-one__wrapper">
              <div className="testimonials-one__pattern">
                <img
                  src="assets/images/pattern/testimonials-one-left-pattern.png"
                  alt=""
                />
              </div>
              <div className="shape1">
                <img src="assets/images/shapes/thm-shape3.png" alt="" />
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="testimonials-one__carousel owl-carousel owl-theme owl-dot-type1">
                    <AliceCarousel
                      responsive={responsive}
                      autoPlay
                      disableButtonsControls
                      infinite
                      autoPlayInterval={5000}
                      animationDuration={2000}
                      controlsStrategy="alternate"
                    >
                      <div
                        className="testimonials-one__single wow fadeInUp"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="testimonials-one__single-inner">
                          <h4 className="testimonials-one__single-title">
                            Amazing Courses
                          </h4>
                          <p className="testimonials-one__single-text">
                            Lorem ipsum is simply free text dolor sit amet,
                            consectetur notted adipisicing elit sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                          <div className="testimonials-one__single-client-info">
                            <div className="testimonials-one__single-client-info-img">
                              <img
                                src="assets/images/testimonial/testimonials-v1-client-info-img1.png"
                                alt=""
                              />
                            </div>
                            <div className="testimonials-one__single-client-info-text">
                              <h5>Kevin Martin</h5>
                              <p>Developer</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="testimonials-one__single wow fadeInUp"
                        data-wow-delay="100ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="testimonials-one__single-inner">
                          <h4 className="testimonials-one__single-title">
                            Amazing Courses
                          </h4>
                          <p className="testimonials-one__single-text">
                            Lorem ipsum is simply free text dolor sit amet,
                            consectetur notted adipisicing elit sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                          <div className="testimonials-one__single-client-info">
                            <div className="testimonials-one__single-client-info-img">
                              <img
                                src="assets/images/testimonial/testimonials-v1-client-info-img2.png"
                                alt=""
                              />
                            </div>
                            <div className="testimonials-one__single-client-info-text">
                              <h5>Christine Eve</h5>
                              <p>Developer</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="testimonials-one__single wow fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="testimonials-one__single-inner">
                          <h4 className="testimonials-one__single-title">
                            Amazing Courses
                          </h4>
                          <p className="testimonials-one__single-text">
                            Lorem ipsum is simply free text dolor sit amet,
                            consectetur notted adipisicing elit sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                          <div className="testimonials-one__single-client-info">
                            <div className="testimonials-one__single-client-info-img">
                              <img
                                src="assets/images/testimonial/testimonials-v1-client-info-img3.png"
                                alt=""
                              />
                            </div>
                            <div className="testimonials-one__single-client-info-text">
                              <h5>David Cooper</h5>
                              <p>Developer</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="testimonials-one__single wow fadeInUp"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="testimonials-one__single-inner">
                          <h4 className="testimonials-one__single-title">
                            Amazing Courses
                          </h4>
                          <p className="testimonials-one__single-text">
                            Lorem ipsum is simply free text dolor sit amet,
                            consectetur notted adipisicing elit sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                          <div className="testimonials-one__single-client-info">
                            <div className="testimonials-one__single-client-info-img">
                              <img
                                src="assets/images/testimonial/testimonials-v1-client-info-img1.png"
                                alt=""
                              />
                            </div>
                            <div className="testimonials-one__single-client-info-text">
                              <h5>Kevin Martin</h5>
                              <p>Developer</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="testimonials-one__single wow fadeInUp"
                        data-wow-delay="100ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="testimonials-one__single-inner">
                          <h4 className="testimonials-one__single-title">
                            Amazing Courses
                          </h4>
                          <p className="testimonials-one__single-text">
                            Lorem ipsum is simply free text dolor sit amet,
                            consectetur notted adipisicing elit sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                          <div className="testimonials-one__single-client-info">
                            <div className="testimonials-one__single-client-info-img">
                              <img
                                src="assets/images/testimonial/testimonials-v1-client-info-img2.png"
                                alt=""
                              />
                            </div>
                            <div className="testimonials-one__single-client-info-text">
                              <h5>Christine Eve</h5>
                              <p>Developer</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="testimonials-one__single wow fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="testimonials-one__single-inner">
                          <h4 className="testimonials-one__single-title">
                            Amazing Courses
                          </h4>
                          <p className="testimonials-one__single-text">
                            Lorem ipsum is simply free text dolor sit amet,
                            consectetur notted adipisicing elit sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                          <div className="testimonials-one__single-client-info">
                            <div className="testimonials-one__single-client-info-img">
                              <img
                                src="assets/images/testimonial/testimonials-v1-client-info-img3.png"
                                alt=""
                              />
                            </div>
                            <div className="testimonials-one__single-client-info-text">
                              <h5>David Cooper</h5>
                              <p>Developer</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="testimonials-one__single wow fadeInUp"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div className="testimonials-one__single-inner">
                          <h4 className="testimonials-one__single-title">
                            Amazing Courses
                          </h4>
                          <p className="testimonials-one__single-text">
                            Lorem ipsum is simply free text dolor sit amet,
                            consectetur notted adipisicing elit sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                          </p>
                          <div className="testimonials-one__single-client-info">
                            <div className="testimonials-one__single-client-info-img">
                              <img
                                src="assets/images/testimonial/testimonials-v1-client-info-img1.png"
                                alt=""
                              />
                            </div>
                            <div className="testimonials-one__single-client-info-text">
                              <h5>Kevin Martin</h5>
                              <p>Developer</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AliceCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
