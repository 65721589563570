import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../../../redux/actions/coursesAction";
import { useParams } from "react-router-dom";

function DetailsBox() {
  const { id } = useParams();
  const courses = useSelector((state) => state?.courses?.courses);
  const [courseData, setCourseData] = useState([]);
  const dispatch = useDispatch();
  const fetchCourseDetail = (id) => {
    const newCourse = courses.filter((x) => x.id === parseInt(id));
    setCourseData(newCourse);
    dispatch(getCourse(id));
  };
  useEffect(() => {
    if (id && id !== "") fetchCourseDetail(id);
  }, [id]);

  return courseData.map((course) => (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage:
            "url(../../../assets/images/backgrounds/page-header-bg.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-header__wrapper clearfix">
                <div className="page-header__title">
                  <h2>Course Details</h2>
                </div>
                <div className="page-header__menu">
                  <ul className="page-header__menu-list list-unstyled clearfix">
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li className="active">Course Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <div className="course-details">
        {" "}
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="course-details__content">
                <div
                  className="courses-one__single style2 wow fadeInLeft"
                  data-wow-delay="0ms"
                  data-wow-duration="1000ms"
                >
                  <div className="courses-one__single-img">
                    <img src={course.image} alt="course details" />
                    <div className="overlay-text">
                      <p>Featured</p>
                    </div>
                  </div>
                  <div className="courses-one__single-content">
                    <div className="courses-one__single-content-overlay-img">
                      <img
                        src={require("../../../assets/images/resources/course-details-overlay-img.png")}
                        alt=""
                      />
                    </div>
                    <h6 className="courses-one__single-content-name">
                      {course.category}
                      <span>Recently Updated 20 June, 2021</span>
                    </h6>
                    <h4 className="courses-one__single-content-title">
                      {course.title}
                    </h4>
                    <div className="courses-one__single-content-review-box">
                      <ul className="list-unstyled">
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                        <li>
                          <i className="fa fa-star"></i>
                        </li>
                      </ul>
                      <div className="rateing-box">
                        <span>(4)</span>
                      </div>
                    </div>
                    <div className="course-details__content-text1">
                      <p>{course.description}</p>
                    </div>

                    <div className="course-details__content-list">
                      <ul className="list-unstyled">
                        <li>
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>It has survived not only five centuries</p>
                          </div>
                        </li>

                        <li>
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>
                              Lorem Ipsum is simply dummy text of the new design
                            </p>
                          </div>
                        </li>

                        <li>
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>Printng and type setting ipsum</p>
                          </div>
                        </li>

                        <li>
                          <div className="icon">
                            <span className="icon-confirmation"></span>
                          </div>
                          <div className="text">
                            <p>Take a look at our round up of the best shows</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-details__curriculum">
                <h2 className="course-details__curriculum-title">Curriculum</h2>
                <div className="course-details__curriculum-single">
                  <h3 className="course-details__curriculum-single-title">
                    Starting Beginners Level Course
                  </h3>
                  <p className="course-details__curriculum-single-text">
                    Aelltes port lacus quis enim var sed efficitur turpis gilla
                    sed sit Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since.
                  </p>
                  <ul className="course-details__curriculum-list list-unstyled">
                    <li>
                      <div className="course-details__curriculum-list-left">
                        <div className="course-details__curriculum-list-left-icon">
                          <i className="fa fa-play" aria-hidden="true"></i>
                        </div>
                        <a
                          href="/"
                          className="course-details__curriculum-list-left-title"
                        >
                          Introduction to Editing
                        </a>
                        <span>Preview</span>
                      </div>
                      <div className="course-details__curriculum-list-right">
                        <p>16 minutes</p>
                      </div>
                    </li>

                    <li>
                      <div className="course-details__curriculum-list-left">
                        <div className="course-details__curriculum-list-left-icon">
                          <i className="fa fa-play" aria-hidden="true"></i>
                        </div>
                        <a
                          href="/"
                          className="course-details__curriculum-list-left-title"
                        >
                          Overview of Editing
                        </a>
                        <span>Preview</span>
                      </div>
                      <div className="course-details__curriculum-list-right">
                        <p>10 minutes</p>
                      </div>
                    </li>

                    <li>
                      <div className="course-details__curriculum-list-left">
                        <div className="course-details__curriculum-list-left-icon">
                          <i className="fa fa-folder" aria-hidden="true"></i>
                        </div>
                        <a
                          href="/"
                          className="course-details__curriculum-list-left-title"
                        >
                          Basic Editing Technology
                        </a>
                      </div>
                    </li>

                    <li>
                      <div className="course-details__curriculum-list-left">
                        <div className="course-details__curriculum-list-left-icon style2">
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </div>
                        <a
                          href="/"
                          className="course-details__curriculum-list-left-title"
                        >
                          Quiz
                        </a>
                      </div>
                      <div className="course-details__curriculum-list-right">
                        <p>6 questions</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="course-details__curriculum-single mar-buttom0">
                  <h3 className="course-details__curriculum-single-title">
                    Intermediate Level Course
                  </h3>
                  <p className="course-details__curriculum-single-text">
                    Aelltes port lacus quis enim var sed efficitur turpis gilla
                    sed sit Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry’s
                    standard dummy text ever since.
                  </p>
                  <ul className="course-details__curriculum-list style2 list-unstyled">
                    <li>
                      <div className="course-details__curriculum-list-left">
                        <div className="course-details__curriculum-list-left-icon">
                          <i className="fa fa-play" aria-hidden="true"></i>
                        </div>
                        <a
                          href="/"
                          className="course-details__curriculum-list-left-title"
                        >
                          Introduction to Editing
                        </a>
                        <span>Preview</span>
                      </div>
                      <div className="course-details__curriculum-list-right">
                        <p>16 minutes</p>
                      </div>
                    </li>

                    <li>
                      <div className="course-details__curriculum-list-left">
                        <div className="course-details__curriculum-list-left-icon">
                          <i className="fa fa-folder" aria-hidden="true"></i>
                        </div>
                        <a
                          href="/"
                          className="course-details__curriculum-list-left-title"
                        >
                          Introduction to Editing
                        </a>
                      </div>
                    </li>

                    <li>
                      <div className="course-details__curriculum-list-left">
                        <div className="course-details__curriculum-list-left-icon style2">
                          <i className="fa fa-comment" aria-hidden="true"></i>
                        </div>
                        <a
                          href="/"
                          className="course-details__curriculum-list-left-title"
                        >
                          Quiz
                        </a>
                      </div>
                      <div className="course-details__curriculum-list-right">
                        <p>6 questions</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="course-details__reviews">
                <h3 className="course-details__reviews-title">Reviews</h3>
                <div className="course-details__progress-review">
                  <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-7">
                      <div className="course-details__progress clearfix">
                        <div className="course-details__progress-item">
                          <p className="course-details__progress-text">
                            Excellent
                          </p>
                          <div className="course-details__progress-bar">
                            <span
                              style={{
                                width: "90%",
                              }}
                            ></span>
                          </div>
                          <p className="course-details__progress-count">2</p>
                        </div>

                        <div className="course-details__progress-item">
                          <p className="course-details__progress-text">
                            Very Good
                          </p>
                          <div className="course-details__progress-bar">
                            <span
                              style={{
                                width: "80%",
                              }}
                            ></span>
                          </div>
                          <p className="course-details__progress-count">1</p>
                        </div>

                        <div className="course-details__progress-item">
                          <p className="course-details__progress-text">
                            Average
                          </p>
                          <div className="course-details__progress-bar">
                            <span
                              style={{
                                width: "70%",
                              }}
                            ></span>
                          </div>
                          <p className="course-details__progress-count">1</p>
                        </div>

                        <div className="course-details__progress-item">
                          <p className="course-details__progress-text">Poor</p>
                          <div className="course-details__progress-bar">
                            <span
                              style={{
                                width: "0%",
                              }}
                              className="no-bubble"
                            ></span>
                          </div>
                          <p className="course-details__progress-count">0</p>
                        </div>

                        <div className="course-details__progress-item">
                          <p className="course-details__progress-text">
                            Terrible
                          </p>
                          <div className="course-details__progress-bar">
                            <span
                              style={{
                                width: "0%",
                              }}
                              className="no-bubble"
                            ></span>
                          </div>
                          <p className="course-details__progress-count">0</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-5 col-lg-5 col-md-5">
                      <div className="course-details__review-box">
                        <h2 className="course-details__review-count">4.6</h2>
                        <div className="course-details__review-stars">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                        <p className="course-details__review-text">
                          30 reviews
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="course-details__comment">
                  <div className="course-details__comment-single">
                    <div className="course-details__comment-img">
                      <img
                        src="assets/images/resources/course-details-comment-img1.png"
                        alt=""
                      />
                    </div>
                    <div className="course-details__comment-text">
                      <div className="course-details__comment-text-top">
                        <h3 className="course-details__comment-text-name">
                          David Marks
                        </h3>
                        <p>3 hours ago</p>
                        <div className="course-details__comment-review-stars">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p className="course-details__comment-text-bottom">
                        Cursus massa at urnaaculis estie. Sed aliquamellus vitae
                        ultrs condmentum lightly believable. If you are going to
                        use a of you need to be sure there.
                      </p>
                    </div>
                  </div>

                  <div className="course-details__comment-single">
                    <div className="course-details__comment-img">
                      <img
                        src="assets/images/resources/course-details-comment-img2.png"
                        alt=""
                      />
                    </div>
                    <div className="course-details__comment-text">
                      <div className="course-details__comment-text-top">
                        <h3 className="course-details__comment-text-name">
                          Christine Eve
                        </h3>
                        <p>3 hours ago</p>
                        <div className="course-details__comment-review-stars">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p className="course-details__comment-text-bottom">
                        Cursus massa at urnaaculis estie. Sed aliquamellus vitae
                        ultrs condmentum lightly believable. If you are going to
                        use a of you need to be sure there.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="course-details__add-review">
                  <h2 className="course-details__add-review-title">
                    Add a Review
                  </h2>
                  <p className="course-details__add-review-text">
                    Rate this Course?
                    <a href="/" className="fas fa-star active pd-left"></a>
                    <a href="/" className="fas fa-star active"></a>
                    <a href="/" className="fas fa-star active"></a>
                    <a href="/" className="fas fa-star"></a>
                    <a href="/" className="fas fa-star"></a>
                  </p>
                  Name
                  <div className="course-details__add-review-form">
                    <form
                      action="assets/inc/sendemail.php"
                      className="comment-one__form contact-form-validated"
                      novalidate="novalidate"
                    >
                      <div className="row">
                        <div className="col-xl-6 col-lg-6">
                          <div className="comment-form__input-box">
                            <input
                              type="text"
                              placeholder="Your name"
                              name="name"
                            />
                          </div>
                          <div className="comment-form__input-box">
                            <input
                              type="email"
                              placeholder="Email address"
                              name="email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12">
                          <div className="comment-form__input-box">
                            <textarea
                              name="message"
                              placeholder="Write message"
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="thm-btn comment-form__btn"
                          >
                            Submit review
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4">
              <div className="course-details__sidebar">
                <div
                  className="course-details__price wow fadeInUp animated"
                  data-wow-delay="0.1s"
                >
                  <h2 className="course-details__price-amount">
                    ${course.price}
                    <span>
                      <del>$60.00</del>
                    </span>
                  </h2>
                  <div className="course-details__price-btn">
                    <a href="about.html" className="thm-btn">
                      Buy this course
                    </a>
                  </div>
                </div>

                <div
                  className="course-details__sidebar-meta wow fadeInUp animated"
                  data-wow-delay="0.3s"
                >
                  <ul className="course-details__sidebar-meta-list list-unstyled">
                    <li className="course-details__sidebar-meta-list-item">
                      <div className="icon">
                        <a href="/">
                          <i className="far fa-clock"></i>
                        </a>
                      </div>
                      <div className="text">
                        <p>
                          <a href="/">
                            Durations:
                            <span>
                              {course.hours}
                              hours
                            </span>
                          </a>
                        </p>
                      </div>
                    </li>

                    <li className="course-details__sidebar-meta-list-item">
                      <div className="icon">
                        <a href="/">
                          <i className="far fa-folder-open"></i>
                        </a>
                      </div>
                      <div className="text">
                        <p>
                          <a href="/">
                            Lectures:
                            <span>{course.lectures}</span>
                          </a>
                        </p>
                      </div>
                    </li>

                    <li className="course-details__sidebar-meta-list-item">
                      <div className="icon">
                        <a href="/">
                          <i className="far fa-user-circle"></i>
                        </a>
                      </div>
                      <div className="text">
                        <p>
                          <a href="/">
                            Students:
                            <span>Max 6</span>
                          </a>
                        </p>
                      </div>
                    </li>

                    <li className="course-details__sidebar-meta-list-item">
                      <div className="icon">
                        <a href="/">
                          <i className="fas fa-play"></i>
                        </a>
                      </div>
                      <div className="text">
                        <p>
                          <a href="/">
                            Video:
                            <span>
                              {course.hours}
                              hours
                            </span>
                          </a>
                        </p>
                      </div>
                    </li>

                    <li className="course-details__sidebar-meta-list-item">
                      <div className="icon">
                        <a href="/">
                          <i className="far fa-flag"></i>
                        </a>
                      </div>
                      <div className="text">
                        <p>
                          <a href="/">
                            Skill Level:
                            <span>{course.skillLevel}</span>
                          </a>
                        </p>
                      </div>
                    </li>

                    <li className="course-details__sidebar-meta-list-item">
                      <div className="icon">
                        <a href="/">
                          <i className="far fa-bell"></i>
                        </a>
                      </div>
                      <div className="text">
                        <p>
                          <a href="/">
                            Language:
                            <span>{course.language}</span>
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div
                  className="course-details__new-courses wow fadeInUp animated"
                  data-wow-delay="0.5s"
                >
                  <h3 className="course-details__new-courses-title">
                    New Courses
                  </h3>
                  <ul className="course-details__new-courses-list list-unstyled">
                    <li className="course-details__new-courses-list-item">
                      <div className="course-details__new-courses-list-item-img">
                        <img
                          src={require("../../../assets/images/resources/course-details-sidebar-img1.png")}
                          alt=""
                        />
                      </div>
                      <div className="course-details__new-courses-list-item-content">
                        <h4 className="course-details__new-courses-list-item-content-title">
                          <a href="/">
                            React – The Complete
                            <br />
                            Instruction
                          </a>
                        </h4>
                        <div className="course-details__new-courses-rateing-box">
                          <ul className="list-unstyled">
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                          </ul>
                          <div className="course-details__new-courses-rateing-count">
                            <span>(4)</span>
                          </div>
                        </div>
                        <p className="course-details__new-courses-price">
                          $30.00
                        </p>
                      </div>
                    </li>

                    <li className="course-details__new-courses-list-item">
                      <div className="course-details__new-courses-list-item-img">
                        <img
                          src={require("../../../assets/images/resources/course-details-sidebar-img2.png")}
                          alt=""
                        />
                      </div>
                      <div className="course-details__new-courses-list-item-content">
                        <h4 className="course-details__new-courses-list-item-content-title">
                          <a href="/">
                            React – The Complete
                            <br />
                            Instruction
                          </a>
                        </h4>
                        <div className="course-details__new-courses-rateing-box">
                          <ul className="list-unstyled">
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                          </ul>
                          <div className="course-details__new-courses-rateing-count">
                            <span>(4)</span>
                          </div>
                        </div>
                        <p className="course-details__new-courses-price">
                          $30.00
                        </p>
                      </div>
                    </li>

                    <li className="course-details__new-courses-list-item">
                      <div className="course-details__new-courses-list-item-img">
                        <img
                          src={require("../../../assets/images/resources/course-details-sidebar-img3.png")}
                          alt=""
                        />
                      </div>
                      <div className="course-details__new-courses-list-item-content">
                        <h4 className="course-details__new-courses-list-item-content-title">
                          <a href="/">
                            React – The Complete
                            <br />
                            Instruction
                          </a>
                        </h4>
                        <div className="course-details__new-courses-rateing-box">
                          <ul className="list-unstyled">
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                          </ul>
                          <div className="course-details__new-courses-rateing-count">
                            <span>(4)</span>
                          </div>
                        </div>
                        <p className="course-details__new-courses-price">
                          $30.00
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ));
}

export default DetailsBox;
