import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../../redux/actions/coursesAction";
import { Link } from "react-router-dom";

function HomeCourses() {
  const courses = useSelector((state) => state?.course?.courses);
  console.log(courses);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCourses());
  }, []);
  return (
    <section className="courses-one">
      <div className="container">
        <div className="section-title text-center">
          <span className="section-title__tagline">
            Explore the world of possible careers
          </span>
          <h2 className="section-title__title">
            {" "}
            and skills by taking a variety of courses{" "}
          </h2>
        </div>
        <div className="row">
          {!courses && <span>loading</span>}
          {courses.map((course) => (
            <div key={course.id} className="col-xl-3 col-lg-6 col-md-6">
              <div
                className="courses-one__single wow fadeInLeft"
                data-wow-delay="0ms"
                data-wow-duration="1000ms"
              >
                <div className="courses-one__single-img">
                  <img src={course.image} alt="" />
                  <div className="overlay-text">
                    <p>Featured</p>
                  </div>
                </div>
                <div className="courses-one__single-content">
                  <div className="courses-one__single-content-overlay-img">
                    <img
                      src={require("../../../assets/images/resources/courses-v1-overlay-img1.png")}
                      alt=""
                    />
                  </div>
                  <h6 className="courses-one__single-content-name">
                    {course.category}
                  </h6>
                  <h4 className="courses-one__single-content-title">
                    <Link to={`/courseDetails/${course.id}`}>
                      {course.title}
                    </Link>
                  </h4>
                  <div className="courses-one__single-content-review-box">
                    <ul className="list-unstyled">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <div className="rateing-box">
                      <span>(4)</span>
                    </div>
                  </div>
                  <p className="courses-one__single-content-price">
                    ${course.price}/N1000/Rs1000
                  </p>
                  <ul className="courses-one__single-content-courses-info list-unstyled">
                    <li>2 Lessons</li>
                    <li>10 Hours</li>
                    <li>Beginner</li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HomeCourses;
