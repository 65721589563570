import React from "react";

function HomeRegister() {
  return (
    <section
      className="registration-one jarallax"
      data-jarallax
      data-speed="0.2"
      data-imgposition="50% 0%"
    >
      <div
        className="registration-one__bg"
        style={{
          backgroundImage: "url(/registerbg.png)",
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-7">
            <div className="registration-one__left">
              <div className="section-title">
                <span className="section-title__tagline">
                  Get Free Registration
                </span>
                <h2 className="section-title__title">
                  Register today on SkillTube
                </h2>
              </div>
              <p className="registration-one__left-text">
                {" "}
                and get immediate access to 0ver 200 online courses and you're
                choosing to spend little money on something that will give you
                an enormous return.
                <br />
                <br />
                <br />
              </p>
              <div className="registration-one__left-transform-box">
                <div className="registration-one__left-transform-box-icon">
                  <span className="icon-online-course"></span>
                </div>
                <div className="registration-one__left-transform-box-text">
                  <h3>
                    <a href="#">Transforming Access To Hing in Demand Skills</a>
                  </h3>
                  <p>
                    Discover a glocal skill-up revolution giving shape to a new
                    way of learning.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5">
            <div
              className="registration-one__right wow slideInRight"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <div className="registration-one__right-form">
                <div className="title-box">
                  <h4>Fill your Registration</h4>
                </div>
                <div className="form-box">
                  <form method="post" action="index.html">
                    <div className="form-group">
                      <input
                        type="text"
                        name="username"
                        placeholder="Your Name"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone"
                        required=""
                      />
                    </div>
                    <div className="form-group">
                      <textarea placeholder="Comment"></textarea>
                    </div>
                    <button
                      className="registration-one__right-form-btn"
                      type="submit"
                      name="submit-form"
                    >
                      <span className="thm-btn">apply for it</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeRegister;
