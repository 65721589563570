export const ACTION_TYPES = {
    ACTION_START: "ACTION_START",
    ACTION_END: "ACTION_END",
    ACTION_FAILED: "ACTION_FAILED",
    ACTION_CLEAR: "ACTION_CLEAR",
    ACTION_SUCCESS: "ACTION_SUCCESS",
    ACTION_ALERT: "ACTION_ALERT",
    ACTION_AUTH: "ACTION_AUTH",
    ACTION_STATUS: "ACTION_STATUS",
    ACTION_PEER: "ACTION_PEER"
}


export const COURSE_TYPES = {
    CREATE_COURSE: 'CREATE_COURSE',
    LOADING_COURSE: 'LOADING_COURSE',
    GET_COURSES: 'GET_COURSES',
    UPDATE_COURSE: 'UPDATE_COURSE',
    GET_COURSE: 'GET_COURSE',
    DELETE_COURSE: 'DELETE_COURSE',
}

export const AUTH_TYPES = {
    ADMIN_LOGIN_START: "ADMIN_LOGIN_START",
    ADMIN_LOGIN: "ADMIN_LOGIN",
    ADMIN_LOGIN_END: " ADMIN_LOGIN_END",
    ADMIN_REGISTER_START: "ADMIN_REGISTER_START",
    ADMIN_REGISTER: "ADMIN_REGISTER",
    ADMIN_REGISTER_END: "ADMIN_REGISTER_END",
    ADMIN_CHANGE_PASSWORD: "ADMIN_CHANGE_PASSWORD",
    ADMIN_VERIFY: "ADMIN_VERIFY",
    ADMIN_GET_ID: "ADMIN_GET_ID"
}

export const PROFILE_TYPES = {
    PROFILE_GET_ID: "PROFILE_GET_ID",
    PROFILE_LOADING: "PROFILE_LOADING",
    PROFILE_COURSE: "PROFILE_COURSE",
    PROFILE_USER: "PROFILE_USER",
    PROFILE_COURSE_UPDATE: "PROFILE_COURSE_UPDATE"
}

