import { getDataAPI } from '../../config/fetchData'
import * as types from "./ActionTypes";


export const getCourses = (token) => async (dispatch) => {
  try {
      dispatch({ type: types.COURSE_TYPES.LOADING_COURSE, payload: true })
      const res = await getDataAPI('courses', token)
      
      dispatch({
          type: types.COURSE_TYPES.GET_COURSES,
          payload: {...res.data, page: 2}
      })

      dispatch({ type: types.COURSE_TYPES.LOADING_COURSE, payload: false })
  } catch (err) {
      dispatch({
          type: types.ACTION_TYPES.ACTION_ALERT,
          payload: {error: err.response.data.message}
      })
  }
}

export const getCourse = ({detailCourse, id, auth}) => async (dispatch) => {
    if(detailCourse.every(course => course.id !== id)){
        try {
            const res = await getDataAPI(`course/${id}`, auth.token)
            dispatch({ type: types.COURSE_TYPES.GET_COURSES, payload: res.data.course })
        } catch (err) {
            dispatch({
                type: types.ACTION_TYPES.ACTION_ALERT,
                payload: {error: err.response.data.message}
            })
        }
    }
}
