import courseReducer from "./courseReducer.js";
import authReducer from "./authReducer"
import detailCourseReducer from "./detailCourseReducer"
import profileReducer from "./profileReducer"
import statusReducer from "./statusReducer"
import alertReducer from "./alertReducer"

import { combineReducers } from "redux";

const allReducers = combineReducers({
  course: courseReducer,
  auth: authReducer,
  profile: profileReducer,
  status: statusReducer,
  detailCourse: detailCourseReducer,
  alert: alertReducer
});

export default allReducers;