import React from "react";

function Blogs() {
  return (
    <section className="blog-one">
      <div className="container">
        <div className="section-title text-center">
          <span className="section-title__tagline">Directly from the Blog</span>
          <h2 className="section-title__title">Latest Articles</h2>
        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-4 wow fadeInLeft"
            data-wow-delay="0ms"
            data-wow-duration="1500ms"
          >
            <div className="blog-one__single">
              <div className="blog-one__single-img">
                <img
                  src={require("../../../assets/images/blog/blog-v1-img1.jpg")}
                  alt=""
                />
              </div>
              <div className="blog-one__single-content">
                <div className="blog-one__single-content-overlay-mata-info">
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">
                        <span className="icon-clock"></span>20 June
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-user"></span>Admin{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-chat"></span> Comments
                      </a>
                    </li>
                  </ul>
                </div>
                <h2 className="blog-one__single-content-title">
                  <a href="news-details.html">
                    Helping Answers Stand out in Discussions
                  </a>
                </h2>
                <p className="blog-one__single-content-text">
                  Lorem ipsum is simply free text on used by copytyping
                  refreshing the whole area.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-4 wow fadeInLeft"
            data-wow-delay="300ms"
            data-wow-duration="1500ms"
          >
            <div className="blog-one__single">
              <div className="blog-one__single-img">
                <img
                  src={require("../../../assets/images/blog/blog-v1-img2.jpg")}
                  alt=""
                />
              </div>
              <div className="blog-one__single-content">
                <div className="blog-one__single-content-overlay-mata-info">
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">
                        <span className="icon-clock"></span>20 June
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-user"></span>Admin{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-chat"></span> Comments
                      </a>
                    </li>
                  </ul>
                </div>
                <h2 className="blog-one__single-content-title">
                  <a href="news-details.html">
                    Helping Answers Stand out in Discussions
                  </a>
                </h2>
                <p className="blog-one__single-content-text">
                  Lorem ipsum is simply free text on used by copytyping
                  refreshing the whole area.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-4 col-lg-4 wow fadeInLeft"
            data-wow-delay="600ms"
            data-wow-duration="1500ms"
          >
            <div className="blog-one__single">
              <div className="blog-one__single-img">
                <img
                  src={require("../../../assets/images/blog/blog-v1-img3.jpg")}
                  alt=""
                />
              </div>
              <div className="blog-one__single-content">
                <div className="blog-one__single-content-overlay-mata-info">
                  <ul className="list-unstyled">
                    <li>
                      <a href="#">
                        <span className="icon-clock"></span>20 June
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-user"></span>Admin{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon-chat"></span> Comments
                      </a>
                    </li>
                  </ul>
                </div>
                <h2 className="blog-one__single-content-title">
                  <a href="news-details.html">
                    Helping Answers Stand out in Discussions
                  </a>
                </h2>
                <p className="blog-one__single-content-text">
                  Lorem ipsum is simply free text on used by copytyping
                  refreshing the whole area.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
