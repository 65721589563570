import React from 'react'
import TopHeader from "../../../components/shared/header/TopHeader";
import Footer from "../../../components/shared/footer/Footer";
import Slider from "../features/Slider";
import DetailsBox from "./DetailsBox"

function CourseDetails() {
  return (
    <>
    <TopHeader />
    <DetailsBox />
    <Footer />
    </>
  )
}

export default CourseDetails