import * as types from "../actions/ActionTypes";


const statusReducer = (state = false, action) => {
    switch (action.type){
        case types.ACTION_TYPES.ACTION_STATUS:
            return action.payload;
        default:
            return state;
    }
}


export default statusReducer