import React, { useState, useEffect } from "react";
import TopHeader from "../../components/shared/header/TopHeader";
import Footer from "../../components/shared/footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

function Login() {
  const initialState = { email: '', password: '' }
  const [userData, setUserData] = useState(initialState)
  const { email, password } = userData

  const [typePass, setTypePass] = useState(false)

  const { auth } = useSelector(state => state);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
      if(auth.token) dispatch(() => navigate("/"))
  }, [auth.token, dispatch, navigate])

  const handleChangeInput = e => {
      const { name, value } = e.target
      setUserData({...userData, [name]:value})
  }

  const handleSubmit = e => {
      e.preventDefault()
      dispatch(login(userData))
  }

  return (
    <>
        <TopHeader />
        <div style={{
                width: "100%",
                height: "100%", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center", 
                marginTop: "-3rem"
            }}
        >
            <div
              className="registration-one__right wow slideInRight"
              data-wow-delay="100ms"
              data-wow-duration="2500ms"
            >
              <div className="registration-one__right-form" style={{width: "100%"}}>
                <div className="title-box">
                  <h4>Sign In</h4>
                </div>
                <div className="form-box">
                  <form method="post" action="index.html">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email Address"
                        required=""
                        onChange={handleChangeInput}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        required=""
                        onChange={handleChangeInput}
                      />
                    </div>
                    <button
                      className="registration-one__right-form-btn"
                      type="submit"
                      name="submit-form"
                      style={{margin: "2rem 0rem"}}
                      onClick={handleSubmit}
                    >
                      <span className="thm-btn">Sign In</span>
                    </button>
                    <div style={{
                        width: "100%",
                        height: "100%", 
                        display: "flex", 
                        justifyContent: "center", 
                        alignItems: "center",
                        flexDirection: "column"
                    }}
                    >
                      <p>or <a href="/">forgot password</a></p>
                      <p>Don't have an account? <a href="/register">Sign up.</a></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        <Footer />
    </>
  )
}

export default Login