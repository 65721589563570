import "./assets/vendors/bootstrap/css/bootstrap.min.css";
import "./assets/vendors/animate/animate.min.css";
import "./assets/vendors/animate/custom-animate.css";
import "./assets/vendors/fontawesome/css/all.min.css";
import "./assets/vendors/jarallax/jarallax.css";
import "./assets/vendors/jquery-magnific-popup/jquery.magnific-popup.css";
import "./assets/vendors/nouislider/nouislider.min.css";
import "./assets/vendors/nouislider/nouislider.pips.css";
import "./assets/vendors/odometer/odometer.min.css";
import "./assets/vendors/swiper/swiper.min.css";
import "./assets/vendors/icomoon-icons/style.css";
import "./assets/vendors/tiny-slider/tiny-slider.min.css";
import "./assets/vendors/reey-font/stylesheet.css";
import "./assets/vendors/owl-carousel/owl.theme.default.min.css";
import "./assets/vendors/twentytwenty/twentytwenty.css";

import "./zilom.css";
import "./zilom-responsive.css";

import WebRoutes from "./WebRoutes";

function App() {
  return <WebRoutes></WebRoutes>;
}

export default App;
