import axios from 'axios';
const BASE_URL = "https://learningapi.7thtower.com";

export const getDataAPI = async(url, token) => {
    const res = await axios.get(`${BASE_URL}/api/${url}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    })

    return res;
}

export const postDataAPI = async(url, course, token) => {
    const res = await axios.post(`${BASE_URL}/api/${url}`, course, {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });

    return res;
}

export const updateDataAPI = async(url, course, token) => {
    const res = await axios.put(`${BASE_URL}/api/${url}`, course, {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });

    return res;
}

export const deleteDataAPI = async(url, token) => {
    const res = await axios.delete(`${BASE_URL}/api/${url}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    });

    return res;
}