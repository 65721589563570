import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./views/Home";
import CourseDetails from "./views/Home/details/CourseDetails";
import Login from "./views/authentication/Login"
import Register from "./views/authentication/Register"

import Alert from './components/shared/alert/Alert'

function WebRoutes() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Alert />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/courseDetails/:id" element={<CourseDetails />} />
        {/* <Route exact path="/404" component={NotFound} />
        <Route exact path="/500" component={BackendError} /> */}
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
