import * as types from "../actions/ActionTypes";
import { EditData } from "../actions/controllers"

const detailCourseReducer = (state = [], action) => {
    switch (action.type){
        case types.COURSE_TYPES.GET_COURSES:
            return [...state, action.payload]
        case types.COURSE_TYPES.UPDATE_COURSE:
            return EditData(state, action.payload.id, action.payload)
        default:
            return state;
    }
}


export default detailCourseReducer