import * as types from "../actions/ActionTypes";

const initialState = {}

const alertReducer = (state = initialState, action) => {
    switch (action.type){
        case types.ACTION_TYPES.ACTION_ALERT:
            return action.payload;
        default:
            return state;
    }
}


export default alertReducer