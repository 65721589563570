import React from "react";

function HomeAbout() {
  return (
    <section className="about-one clearfix">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="about-one__left">
              <ul className="about-one__left-img-box list-unstyled clearfix">
                <li className="about-one__left-single">
                  <div className="about-one__left-img1">
                    <img
                      src={require("../../../assets/images/about/about-v1-img1.png")}
                      alt=""
                    />
                  </div>
                </li>
                <li className="about-one__left-single">
                  <div className="about-one__left-img2">
                    <img
                      src={require("../../../assets/images/about/about-v1-img2.png")}
                      alt=""
                    />
                  </div>
                </li>
              </ul>
              <div className="about-one__left-overlay">
                <div className="icon">
                  <span className="icon-relationship"></span>
                </div>
                <div className="title">
                  <h6>
                    Over 650,000 students agree, we deliver the highest quality
                    online training in the marketplace at affordable prices!
                    customers
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6">
            <div className="about-one__right">
              <div className="section-title">
                <span className="section-title__tagline"></span>
                <h2 className="section-title__title">With SkillTube</h2>
              </div>
              <div className="about-one__right-inner">
                <p className="about-one__right-text">
                  discover an online course library full of the training you
                  need to expand your skills and make more money. Spanning
                  Technology, business, career and languages, we have over 200
                  courses designed to develop your knowledge and improve your
                  life.
                </p>
                <ul className="about-one__right-list list-unstyled">
                  <li className="about-one__right-list-item">
                    <div className="icon">
                      <span className="icon-confirmation"></span>
                    </div>
                    <div className="text">
                      <p>Get unlimited access to 11,000+ video lectures</p>
                    </div>
                  </li>

                  <li className="about-one__right-list-item">
                    <div className="icon">
                      <span className="icon-confirmation"></span>
                    </div>
                    <div className="text">
                      <p>
                        Extensive Library Covering Today's Best Technologies
                      </p>
                    </div>
                  </li>

                  <li className="about-one__right-list-item">
                    <div className="icon">
                      <span className="icon-confirmation"></span>
                    </div>
                    <div className="text">
                      <p>
                        The opportunity to round out your technical skills in
                        many areas
                      </p>
                    </div>
                  </li>
                </ul>

                <div className="about-one__btn">
                  <a href="about.html" className="thm-btn">
                    view all courses
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeAbout;
